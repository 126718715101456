import React from "react";
import { Box } from "@mui/material";
import { textStyle } from "../../helpers/helper";

export const Fact = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box
      sx={{
        ...textStyle.text,
      }}
    >
      <Box
        sx={{
          textAlign: "start",
          display: "inline",
          color: "#767676",
        }}
      >
        {label}
      </Box>
      <Box sx={{ float: "right", display: "inline" }}>{value}</Box>
    </Box>
  );
};
