import React, { useState } from "react";
import { Button, Menu, MenuItem, styled } from "@mui/material";
import { LoginDialog } from "../dialog/LoginDialog";
import { useUser } from "../../hooks/useUser";
import { useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const StyledLogInButton = styled(Button)(({ theme }) => ({
  height: "50px",
  border: "1px solid #DCDCDC",
  color: "#000000",
  textTransform: "initial",
  "&:hover": {
    backgroundColor: "#F01C1C",
    border: "1px solid #F01C1C",
    color: "#FFFFFF",
    boxShadow:
      "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const OpenLoginDialogButton = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userEnabled, setUserEnabled] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);
  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    localStorage.removeItem("token");
    setAnchorEl(null);
    window.location.reload();
  };

  const token = localStorage.getItem("token");
  const { data: user } = useUser(token, userEnabled);

  return (
    <>
      {user ? (
        <StyledLogInButton variant="outlined" onClick={showMenu}>
          <h4>
            <PersonOutlineIcon sx={{ verticalAlign: "bottom" }} />{" "}
            {`${user.first_name} ${user.last_name}`}
          </h4>
        </StyledLogInButton>
      ) : (
        <StyledLogInButton variant="outlined" onClick={() => setOpen(true)}>
          <h4>Sign up or Log in</h4>
        </StyledLogInButton>
      )}
      <LoginDialog
        open={open}
        setOpen={setOpen}
        setUserEnabled={setUserEnabled}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={closeMenu}
        MenuListProps={{
          style: {
            minWidth: 160,
          },
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/profile");
            closeMenu();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/my-listings");
            closeMenu();
          }}
        >
          My Listings
        </MenuItem>
        <MenuItem onClick={logOut}>Logout</MenuItem>
      </Menu>
    </>
  );
};
