import { Place } from "models/places";
import { useQuery } from "react-query";
import { requestGetImls } from "../helpers/requestHelper";

export const usePalaces = (searchText: string) => {
  return useQuery(
    ["places", searchText],
    async () => {
      const response = await requestGetImls<Place[]>(
        `places?location=${searchText}`
      );
      return response;
    },
    { enabled: !!searchText }
  );
};
