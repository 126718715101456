import React, { useState } from "react";
import { Button, Grid, List, ListItem, Typography } from "@mui/material";
import { Option } from "models/option";

export const listItemSx = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "16px",
};

export const ImlsList = ({
  title,
  items = [],
  listSx = {},
  titleSx = {},
  isEditable = false,
  content = <></>,
  editContent = <></>,
  isEditMode = false,
  setIsEditMode,
}: {
  title: string;
  items?: Option[];
  listSx?: {};
  titleSx?: {};
  isEditable?: boolean;
  content?: React.ReactNode;
  editContent?: React.ReactNode;
  isEditMode?: boolean;
  setIsEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <List
      sx={{
        boxShadow:
          "0px 0px 4px rgba(0, 0, 0, 0.04), 8px 8px 16px rgba(0, 0, 0, 0.08)",
        borderRadius: "10px",
        py: 0,
        ...listSx,
      }}
    >
      <ListItem
        sx={{
          py: 2,
          background: "#F0F0F0",
          borderRadius: "10px 10px 0px 0px",
          ...titleSx,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "16px",
          }}
        >
          {title}
        </Typography>
        {isEditable && (
          <Button
            variant="contained"
            sx={{
              background: "#F0F0F0",
              border: "1px solid #FFFFFF",
              borderRadius: "50px",
              position: "absolute",
              right: "20px",
              color: "#000",
            }}
            onClick={() => setIsEditMode(!isEditMode)}
          >
            {isEditMode ? "View" : "Edit"}
          </Button>
        )}
      </ListItem>
      {isEditMode ? (
        <>{editContent}</>
      ) : (
        <>
          {items.map((item: Option) => (
            <ListItem divider sx={{ py: 2 }}>
              <Grid item sm={3}>
                <Typography sx={listItemSx}>{item.label}</Typography>
              </Grid>
              <Grid item sm={9}>
                <Typography sx={listItemSx}>{item.value}</Typography>
              </Grid>
            </ListItem>
          ))}
        </>
      )}
      {content}
    </List>
  );
};
