import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  Grid,
  ImageList,
  ImageListItem,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { BasicTextBox } from "components/form/BasicTextBox";
import { BasicAutocomplete } from "components/form/BasicAutocomplete";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "components/firebase/firebase";
import { useCreateListing } from "hooks/useCreateListing";
import { Listing } from "models/listing";
import { useNavigate } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { getErrorMessage } from "helpers/helper";
import { NewListingSteps } from "components/Page/MyListings/NewListingSteps";
import { useUpdateListing } from "hooks/useUpdateListing";
import { useLatLng } from "hooks/useLatLng";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { icon } from "components/common/Listing";
import useDebounce from "hooks/useDebounce";

const listingTypes = ["For Sale", "For Rent", "Room Rental"];
const propertyTypes = [
  "Good Class Bungalow",
  "Conservation House",
  "Corner Terrace",
  "Detached House",
  "Bungalow House",
  "Cluster House",
  "Land Only",
  "Semi-Detached House",
  "Shophouse",
  "Terrace House",
  "Town House",
];

export const ListingForm = ({
  listing,
  isEdit = false,
}: {
  listing?: Listing;
  isEdit?: boolean;
}) => {
  const [step, setStep] = useState(1);
  const [imgs, setImgs] = useState([] as string[]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { mutate: createListing } = useCreateListing({
    token,
    onSuccess: (_: Listing) => navigate(`/my-listings/Draft`),
    onError: (error: any) =>
      NotificationManager.error(
        getErrorMessage(error),
        "Failed to create new listing"
      ),
  });

  const { mutate: publishListing } = useCreateListing({
    token,
    onSuccess: (_: Listing) => navigate(`/my-listings/In Review`),
    onError: (error: any) =>
      NotificationManager.error(
        getErrorMessage(error),
        "Failed to create new listing"
      ),
  });

  const { mutate: updateListing } = useUpdateListing({
    listingId: listing?.id,
    token,
    onSuccess: (_: Listing) => navigate(`/my-listings/Draft`),
    onError: (error: any) =>
      NotificationManager.error(
        getErrorMessage(error),
        "Failed to create new listing"
      ),
  });

  const { mutate: publishUpdateListing } = useUpdateListing({
    listingId: listing?.id,
    token,
    onSuccess: (_: Listing) => navigate(`/my-listings/In Review`),
    onError: (error: any) =>
      NotificationManager.error(
        getErrorMessage(error),
        "Failed to create new listing"
      ),
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
    watch,
  } = useForm();

  const formLocation = watch("location");
  const searchLocation = useDebounce(formLocation);
  const { data: newLocations } = useLatLng(searchLocation);

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (!file) {
      alert("Please upload an image first!");
    }

    const storageRef = ref(storage, `/files/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (_) => {
        // const percent = Math.round(
        //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        // );
        // update progress
        // setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setImgs([...imgs, url]);
          setValue("images", [...imgs, url]);
        });
      }
    );
  };

  useEffect(() => {
    if (listing) {
      const paths = listing.images.map((image) => image.path);
      setImgs(paths);
      setValue("images", paths);
      setValue("status", "Draft");
    }
  }, [listing]);

  if (isEdit && !listing) {
    return <></>;
  }

  return (
    <Grid container spacing={2} my={2} px={4}>
      <NewListingSteps step={step} setStep={setStep} />
      <form
        onSubmit={handleSubmit(
          (isEdit ? updateListing : createListing) as SubmitHandler<FieldValues>
        )}
        style={{ width: "100%" }}
      >
        <Grid
          item
          sm={6}
          xs={12}
          sx={{
            margin: "auto",
            pr: 4,
            display: step === 1 ? "block" : "none",
          }}
        >
          <FormControl fullWidth>
            <BasicTextBox
              name="location"
              label="Location"
              control={control}
              rules={{
                required: true,
                validate: () => !!newLocations && !!newLocations.length,
              }}
              required
              errors={errors}
              defaultValue={listing?.location}
            />
          </FormControl>
          <Box sx={{ mx: "auto", mt: 3, width: "100%" }}>
            {newLocations && !!newLocations.length && (
              <MapContainer
                center={[newLocations[0].lat, newLocations[0].lon]}
                zoom={13}
                scrollWheelZoom={false}
                style={{ height: 400 }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  position={[newLocations[0].lat, newLocations[0].lon]}
                  icon={icon}
                >
                  <Popup>{formLocation}</Popup>
                </Marker>
              </MapContainer>
            )}
          </Box>
        </Grid>
        <Grid
          container
          sm={6}
          xs={12}
          sx={{
            margin: "auto",
            pr: 4,
            display: step === 2 ? "flex" : "none",
          }}
          spacing={2}
        >
          <Grid item sm={6} xs={6}>
            <FormControl fullWidth>
              <BasicAutocomplete
                name="listing_type"
                control={control}
                options={listingTypes}
                label="Listing Type"
                errors={errors}
                rules={{ required: true }}
                required
                defaultValue={listing?.listing_type}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={6}>
            <FormControl fullWidth>
              <BasicAutocomplete
                name="property_type"
                control={control}
                options={propertyTypes}
                label="Property Type"
                errors={errors}
                rules={{ required: true }}
                required
                defaultValue={listing?.property_type}
              />
            </FormControl>
          </Grid>

          <Grid item sm={6} xs={6}>
            <FormControl fullWidth>
              <BasicTextBox
                name="price"
                label="Price"
                control={control}
                rules={{ required: true }}
                required
                errors={errors}
                type="number"
                defaultValue={listing?.price as unknown as string}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={6}>
            <FormControl fullWidth>
              <BasicTextBox
                name="land_areas"
                label="Land Area"
                control={control}
                rules={{ required: true }}
                required
                errors={errors}
                type="number"
                defaultValue={listing?.land_areas as unknown as string}
              />
            </FormControl>
          </Grid>

          <Grid item sm={4} xs={4}>
            <FormControl fullWidth>
              <BasicTextBox
                name="year_built"
                label="Year Completed"
                control={control}
                type="number"
                defaultValue={listing?.year_built as unknown as string}
              />
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={4}>
            <FormControl fullWidth>
              <BasicTextBox
                name="bed"
                label="Bed"
                control={control}
                rules={{ required: true }}
                required
                errors={errors}
                type="number"
                defaultValue={listing?.bed as unknown as string}
              />
            </FormControl>
          </Grid>
          <Grid item sm={4} xs={4}>
            <FormControl fullWidth>
              <BasicTextBox
                name="bath"
                label="Bath"
                control={control}
                rules={{ required: true }}
                required
                errors={errors}
                type="number"
                defaultValue={listing?.bath as unknown as string}
              />
            </FormControl>
          </Grid>

          <Grid item sm={12} xs={12}>
            <FormControl fullWidth>
              <BasicTextBox
                name="description"
                label="Description"
                control={control}
                rules={{ required: true }}
                required
                errors={errors}
                multiline
                rows={5}
                defaultValue={listing?.description}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          sm={6}
          xs={12}
          sx={{ margin: "auto", pr: 4, display: step === 3 ? "block" : "none" }}
        >
          <Button variant="contained" component="label">
            Upload File
            <input type="file" hidden onChange={handleUpload} />
          </Button>
          <Box>
            <ImageList cols={3} rowHeight={164} sx={{ height: 450 }}>
              {imgs.map((img) => (
                <ImageListItem key={img}>
                  <img
                    src={`${img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    loading="lazy"
                    alt={img}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box>
        </Grid>

        <Grid container spacing={2} my={2} px={4}>
          <Grid container sm={6} xs={12} sx={{ margin: "auto" }}>
            <Grid item sm={4} xs={4} sx={{ px: 2 }}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  onClick={() => setStep(step - 1)}
                  sx={{ mb: 2, mt: 1, my: 4 }}
                >
                  Back
                </Button>
              </FormControl>
            </Grid>
            <Grid item sm={4} xs={4} sx={{ px: 2 }}>
              <FormControl fullWidth>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mb: 2, mt: 1, my: 4 }}
                >
                  Save & Exit
                </Button>
              </FormControl>
            </Grid>
            {step === 3 && (
              <Grid item sm={4} xs={4} sx={{ px: 2 }}>
                <FormControl fullWidth>
                  <Button
                    variant="contained"
                    sx={{ mb: 2, mt: 1, my: 4 }}
                    onClick={async () => {
                      let isValid = false;
                      isValid = await trigger();
                      if (isValid) {
                        const listingParams = {
                          ...getValues(),
                          status: "In Review",
                        } as Listing;
                        if (isEdit) {
                          publishUpdateListing(listingParams);
                        } else {
                          publishListing(listingParams);
                        }
                      }
                    }}
                  >
                    Publish
                  </Button>
                </FormControl>
              </Grid>
            )}
            {step < 3 && (
              <Grid item sm={4} xs={4} sx={{ px: 2 }}>
                <FormControl fullWidth>
                  <Button
                    variant="contained"
                    sx={{ mb: 2, mt: 1, my: 4 }}
                    onClick={async () => {
                      let result = false;
                      if (step === 1) {
                        result = await trigger("location", {
                          shouldFocus: true,
                        });
                      } else if (step === 2) {
                        result = await trigger(
                          [
                            "listing_type",
                            "property_type",
                            "price",
                            "bed",
                            "bad",
                            "description",
                            "land_area",
                          ],
                          {
                            shouldFocus: true,
                          }
                        );
                      }

                      if (result) {
                        setStep(step + 1);
                      }
                    }}
                  >
                    Continue
                  </Button>
                </FormControl>
              </Grid>
            )}
            {/* <Grid item sm={4} xs={4} sx={{ px: 2 }}>
              <FormControl fullWidth>
                <Button
                  variant="contained"
                  sx={{ mb: 2, mt: 1, my: 4 }}
                  onClick={() => {
                    console.log(getValues());
                  }}
                >
                  Test
                </Button>
              </FormControl>
            </Grid> */}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};
