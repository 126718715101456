import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { textStyle } from "../../helpers/helper";
import { useHomeListing } from "../../hooks/useHomeListing";
import { FavoriteButton } from "../form/Favorite";

export const HomeListing = () => {
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState("init");
  const [isCalledGetLocation, setIsCalledGetLocation] = useState(false);

  const { data: homeListings } = useHomeListing(lat, lng, "", {
    enabled: !!isCalledGetLocation,
  });

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("not_supported");
    } else {
      setStatus("locating");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus("success");
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("not_found");
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
    setIsCalledGetLocation(true);
  });

  return (
    <Box sx={{ mx: 4 }}>
      <Grid container spacing={2} my={2}>
        {homeListings?.map((homeListing, index) => (
          <Grid item sm={3} key={index}>
            <Link
              to={`listing/${homeListing.id}/${homeListing.location.replace(
                / /g,
                "-"
              )}`}
            >
              <Card sx={{ height: 400, cursor: "pointer" }}>
                <CardContent>
                  <Box height={200}>
                    <img src={homeListing.path} height="100%" />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: "#F01C1C",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "inline-block",
                        paddingLeft: 2,
                        ...textStyle.text,
                      }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {homeListing.country}
                    </Typography>
                    <Box
                      sx={{
                        ...textStyle.text,
                        fontWeight: 600,
                        fontSize: 24,
                      }}
                    >
                      {homeListing.price
                        ? `$S${homeListing.price.toLocaleString()}`
                        : ""}
                    </Box>
                    <FavoriteButton />
                  </Box>
                  <Box sx={{ pt: 1 }}>
                    {homeListing.bed && (
                      <>
                        <b>{homeListing.bed}</b> beds
                      </>
                    )}
                    {homeListing.bath && (
                      <>
                        {homeListing.bed && " "}
                        <b>{homeListing.bath}</b> baths
                      </>
                    )}
                    {homeListing.square && (
                      <>
                        {(homeListing.bed || homeListing.bath) && " "}
                        <b>{homeListing.square}</b> sq ft
                      </>
                    )}
                  </Box>
                  <Box sx={{ pt: 1, ...textStyle.text }}>
                    {homeListing.location}
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
