import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DividerWithText } from "../form/Divider";
import { useLogin } from "../../hooks/useLogin";
import { User } from "../../models/user";
import { NotificationManager } from "react-notifications";
import { getErrorMessage } from "../../helpers/helper";
import { useQueryClient } from "react-query";
import Singapore from "./Singapore.svg";
import Thailand from "./Thailand.svg";
import Vietnam from "./Vietnam.svg";
import { useUpdateUser } from "hooks/useUpdateUser";

const flags = {
  Singapore,
  Vietnam,
  Thailand,
} as {
  [key: string]: string;
};

export const LoginDialog = ({
  open,
  setOpen,
  setUserEnabled,
}: {
  open: boolean;
  setOpen: any;
  setUserEnabled: (_: boolean) => void;
}) => {
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [schoolMemberNo, setSchoolMemberNo] = useState("");
  const [salesPersonNo, setSalesPersonNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [nric, setNric] = useState("");
  const [otp, setOtp] = useState("");
  const [location, setLocation] = useState("Singapore");
  const [favourite, setFavourite] = useState("");
  const token = localStorage.getItem("token");
  const queryClient = useQueryClient();

  const { mutate: loginUser } = useLogin({
    onSuccess: (user: User) => {
      setLoading(false);
      localStorage.setItem("token", user.token);
      if (user.first_name) {
        setOpen(false);
        setUserEnabled(true);
        queryClient.invalidateQueries("user");
      } else {
        setStep(2);
        setOpen(true);
      }
    },
    onError: (error: any) => {
      NotificationManager.error(getErrorMessage(error), "Failed to login");
      setLoading(false);
      setStep(0);
    },
  });

  const { mutate: updateUser } = useUpdateUser({
    token,
    onSuccess: () => {
      setLoading(false);
      NotificationManager.success(
        "Your profile was updated!",
        "Profile was updated"
      );
      queryClient.invalidateQueries("user");
      setOpen(false);
    },
    onError: (error: any) => {
      setOpen(true);
      NotificationManager.error(
        getErrorMessage(error),
        "Failed to update profile"
      );
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e: any) => {
    if (step === 1) {
      setLoading(true);
      loginUser({ email, password });
      setOpen(false);
    }
    if (step === 7) {
      setLoading(true);
      updateUser({
        role,
        school_member_number: schoolMemberNo,
        sale_person_reg_no: salesPersonNo,
        first_name: firstName,
        last_name: lastName,
        phone,
        nric_fin: nric,
        country: location,
        favourite,
      } as User);
    }
    setStep(step < 7 ? step + 1 : 7);
    e.preventDefault();
  };

  const getTitle = (step: number) => {
    switch (step) {
      case 1:
        return "Account's password";
      case 2:
        return "Your role";
      case 3:
        return "School member number";
      case 4:
        return "Sales persons Registration";
      case 5:
        return "Complete Your Profile";
      case 6:
        return "OTP Sent";
      case 7:
        return "Location";
      default:
        return "Join or Sign in";
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="xs">
      <DialogTitle sx={{ width: 400 }}>{getTitle(step)}</DialogTitle>
      <DialogContent sx={{ minHeight: 140 }}>
        <Box sx={{ py: 1 }}>
          <form onSubmit={handleSubmit}>
            {step === 0 && (
              <TextField
                label="Email"
                variant="outlined"
                size="small"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                sx={{ mb: 2 }}
                fullWidth
                required
              />
            )}
            {step === 1 && (
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                sx={{ mb: 2 }}
                fullWidth
                required
              />
            )}
            {step === 2 && (
              <>
                {[
                  { label: "Real Estate Sales Persons", role: "sale" },
                  { label: "Tenants", role: "tenant" },
                  { label: "Landlords", role: "landlord" },
                  { label: "School member", role: "student" },
                ].map((roleOption) => (
                  <Chip
                    sx={{ mr: 1, mb: 1 }}
                    variant={role === roleOption.role ? undefined : "outlined"}
                    label={roleOption.label}
                    onClick={() => setRole(roleOption.role)}
                    color={role === roleOption.role ? "primary" : undefined}
                  />
                ))}
              </>
            )}
            {step === 3 && (
              <TextField
                label="School member No."
                variant="outlined"
                size="small"
                onChange={(e) => setSchoolMemberNo(e.target.value)}
                value={schoolMemberNo}
                sx={{ mb: 2 }}
                fullWidth
                required
              />
            )}
            {step === 4 && (
              <TextField
                label="Salesperson Reg No."
                variant="outlined"
                size="small"
                onChange={(e) => setSalesPersonNo(e.target.value)}
                value={salesPersonNo}
                sx={{ mb: 2 }}
                fullWidth
                required
              />
            )}
            {step === 5 && (
              <>
                <TextField
                  label="First name"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstName}
                  sx={{ mb: 2 }}
                  fullWidth
                  required
                />
                <TextField
                  label="Last name"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  sx={{ mb: 2 }}
                  fullWidth
                  required
                />
                <TextField
                  label="Phone number"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setPhone(e.target.value)}
                  value={phone}
                  sx={{ mb: 2 }}
                  fullWidth
                  required
                />
                <TextField
                  label="NRIC/FIN"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setNric(e.target.value)}
                  value={nric}
                  sx={{ mb: 2 }}
                  fullWidth
                  required
                />
              </>
            )}
            {step === 6 && (
              <TextField
                label="X-X-X-X"
                variant="outlined"
                size="small"
                onChange={(e) => setOtp(e.target.value)}
                value={otp}
                sx={{ mb: 2 }}
                fullWidth
                required
              />
            )}
            {step === 7 && (
              <>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={["Singapore", "Vietnam", "Thailand"]}
                  value={location}
                  onChange={(_, newValue) => setLocation(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <img src={flags[location]} style={{ width: 40 }} />
                          </InputAdornment>
                        ),
                      }}
                      label="Location"
                    />
                  )}
                />
                <Typography variant="h6" sx={{ mt: 1, mb: 1 }}>
                  Your favourite
                </Typography>
                {[
                  { label: "For sell", value: "sale" },
                  { label: "For rent", value: "rent" },
                  { label: "Only room", value: "only_room" },
                  { label: "Apartment", value: "apartment" },
                  { label: "HDB", value: "hdb" },
                  { label: "Condo", value: "condo" },
                  { label: "Have conditioner", value: "conditioner" },
                ].map((favouriteOption) => (
                  <Chip
                    sx={{ mr: 1, mb: 1 }}
                    variant={
                      favourite === favouriteOption.value
                        ? undefined
                        : "outlined"
                    }
                    label={favouriteOption.label}
                    onClick={() => setFavourite(favouriteOption.value)}
                    color={
                      favourite === favouriteOption.value
                        ? "primary"
                        : undefined
                    }
                  />
                ))}
              </>
            )}
            {step < 2 && (
              <>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mb: 2, mt: 1 }}
                  fullWidth
                  disabled={loading}
                >
                  Submit
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </>
            )}
            {step > 1 && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mb: 2, mt: 1 }}
                    type="submit"
                    fullWidth
                  >
                    Confirm
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{ mb: 2, mt: 1 }}
                    fullWidth
                    onClick={() => setStep(step - 1)}
                  >
                    Back
                  </Button>
                </Grid>
              </Grid>
            )}
          </form>
          {step < 1 && (
            <>
              <DividerWithText>OR</DividerWithText>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mb: 2, mt: 1 }}
                fullWidth
              >
                Continue with Facebook
              </Button>
            </>
          )}
          {step < 2 && (
            <p
              style={{
                fontFamily: "Montserrat",
                fontStyle: "normal",
                fontWeight: 400,
                color: "#707070",
              }}
            >
              By signing in you agree to Redfin's Terms of Use and Privacy
              Policy
            </p>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
