import React from "react";
import { Box, Typography } from "@mui/material";

export const ProfileTitle = ({ title }: { title: string }) => {
  return (
    <Box sx={{ display: "flex", mb: 3 }}>
      <Box sx={{ background: "#F01C1C", width: "9px", height: 36 }}></Box>
      <Typography variant="h5" sx={{ fontWeight: "bold", pl: 1 }}>
        {title}
      </Typography>
    </Box>
  );
};
