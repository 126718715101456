import React from "react";
import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useListing } from "../../hooks/useListing";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";
import { textStyle } from "../../helpers/helper";
import { Fact } from "../listing/Fact";

export const icon = L.icon({ iconUrl: "/images/marker-icon.png" });

export const Listing = () => {
  const { listingId } = useParams();

  const { data: listing } = useListing(listingId as unknown as number);

  if (!listing) {
    return <></>;
  }

  return (
    <Box>
      <Grid container sx={{ mt: 2 }}>
        <Grid item sm={6}>
          <Box>
            <ImageGallery
              items={listing.images.map((image) => ({
                original: image.path,
                thumbnail: image.path,
                originalHeight: 293,
              }))}
            />
          </Box>
        </Grid>
        <Grid item sm={6} sx={{ pl: 1, pr: 2 }}>
          {listing.lat && listing.lng && (
            <MapContainer
              center={[listing.lat, listing.lng]}
              zoom={13}
              scrollWheelZoom={false}
              style={{ height: 400 }}
            >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={[listing.lat, listing.lng]} icon={icon}>
                <Popup>{listing.location}</Popup>
              </Marker>
            </MapContainer>
          )}
        </Grid>
        <Grid item sm={8} sx={{ p: 2 }}>
          <Box
            sx={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: 32,
              lineHeight: "39px",
            }}
          >
            {listing.location}
          </Box>
          <Box
            sx={{
              ...textStyle.text,
            }}
          >
            <div
              className="post__content"
              dangerouslySetInnerHTML={{ __html: listing.description }}
            ></div>
          </Box>
          <Box
            sx={{
              ...textStyle.text,
              fontWeight: 600,
              mb: 1,
            }}
          >
            Home Facts
          </Box>
          <Box
            sx={{
              ...textStyle.text,
            }}
          >
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <Fact label="Status" value={listing.type_status} />
                <Fact label="Property Type" value={listing.property_type} />
              </Grid>
              <Grid item sm={6}>
                <Fact
                  label="Year Build"
                  value={listing.year_built as unknown as string}
                />
                <Fact
                  label="Lot Size"
                  value={listing.land_areas as unknown as string}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
