import { useQuery } from "react-query";
import { requestGetImls } from "../helpers/requestHelper";
import { User } from "../models/user";

export const useUser = (token: string, enabled?: boolean) => {
  return useQuery(
    "user",
    async () => {
      const response = await requestGetImls<User>(
        `user/whoami?access_token=${token}`
      );
      return response;
    },
    { enabled: !!token || enabled }
  );
};
