import { styled } from "@mui/material";
import React, { memo } from "react";
import banner from "../../img/banner.png";
import { HomeListing } from "./HomeListing";
import SearchField from "./SearchField";

const StyledBannerWrapper = styled("div")(({ theme }) => ({
  padding: "150px",
  textAlign: "center",
  background: "#1abc9c",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage: "url('" + banner + "')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  [theme.breakpoints.down("sm")]: {
    padding: "60px",
  },
}));

const StyledBannerText = styled("p")(({ theme }) => ({
  color: "white",
  fontSize: "48px",
  maxWidth: "500px",
  fontWeight: "700",
  lineHeight: "59px",
  letterSpacing: "2px",
  marginBottom: "20px",
  [theme.breakpoints.down("sm")]: {
    fontSize: "30px",
    lineHeight: "40px",
    letterSpacing: "1px",
  },
}));

export default memo<{}>(({}) => {
  return (
    <>
      <StyledBannerWrapper>
        <StyledBannerText>
          Discover a place You’ll love to live
        </StyledBannerText>
        <div style={{ maxWidth: "500px", width: "100%" }}>
          <SearchField />
        </div>
      </StyledBannerWrapper>
      <HomeListing />
    </>
  );
});
