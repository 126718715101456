import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import { Dispatch, SetStateAction } from "react";

const style = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
};

export const ListingStatus = ({
  status,
  setStatus,
}: {
  status: string | string[];
  setStatus: Dispatch<SetStateAction<string | string[]>>;
}) => {
  const statuses = [
    "Active",
    "Draft",
    "In Review",
    "Inactive",
    "Expired",
    ["Sold", "Rented"],
  ];
  return (
    <List sx={style} component="nav" aria-label="mailbox folders">
      {statuses.map((listingStatus) => {
        const isCurrentStatus =
          (typeof listingStatus === "string" && listingStatus === status) ||
          JSON.stringify(listingStatus) === JSON.stringify(status);

        return (
          <>
            <ListItem
              sx={{
                backgroundColor: isCurrentStatus ? "#ED1B24" : "",
                borderRadius: 1,
                color: isCurrentStatus ? "#fff" : "",
                "&:hover": {
                  color: "#000",
                },
              }}
              onClick={() => setStatus(listingStatus)}
              button
            >
              <ListItemText
                primary={
                  typeof listingStatus === "string"
                    ? listingStatus
                    : listingStatus.join(", ")
                }
              />
            </ListItem>
            <Divider />
          </>
        );
      })}
    </List>
  );
};
