import { Agency, AgencyCreate } from "models/agency";
import { useMutation } from "react-query";
import { requestPostImls } from "../helpers/requestHelper";

export const useBecomeAgent = ({ token, onSuccess, onError }: any) => {
  return useMutation(
    async (agency: AgencyCreate) => {
      return await requestPostImls<AgencyCreate, Agency>(
        `agency_profile/create?token=${token}`,
        agency
      );
    },
    {
      onSuccess,
      onError,
    }
  );
};
