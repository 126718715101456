import { useMutation } from "react-query";
import { requestPostImls } from "../helpers/requestHelper";
import { LoginData } from "../models/login";
import { User } from "../models/user";

export const useLogin = ({ onSuccess, onError }: any) => {
  return useMutation(
    async (loginData: LoginData) => {
      const response = await requestPostImls<LoginData, User>(
        "user/login",
        loginData
      );
      return response;
    },
    { onSuccess, onError }
  );
};
