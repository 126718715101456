import React, { useEffect } from "react";
import { Button, Grid, ListItem, Typography } from "@mui/material";
import { BasicTextBox } from "components/form/BasicTextBox";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useUser } from "hooks/useUser";
import { listItemSx } from "../ImlsList";
import { useUpdateUser } from "hooks/useUpdateUser";
import { NotificationManager } from "react-notifications";
import { getErrorMessage } from "helpers/helper";
import { useQueryClient } from "react-query";

export const PasswordUpdate = ({
  setIsEditMode,
}: {
  setIsEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const token = localStorage.getItem("token");
  const { data: user } = useUser(token);
  const queryClient = useQueryClient();

  const { mutate: updateUser } = useUpdateUser({
    token,
    onSuccess: () => {
      setIsEditMode(false);
      NotificationManager.success(
        "Your profile was updated!",
        "Profile was updated"
      );
      queryClient.invalidateQueries("user");
    },
    onError: (error: any) =>
      NotificationManager.error(
        getErrorMessage(error),
        "Failed to update profile"
      ),
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
    getValues,
  } = useForm();

  useEffect(() => {
    if (user) {
      setValue("app", "ilms");
      setValue("is_rs_sale_person", user.is_rs_sale_person);
      setValue("sale_person_reg_no", user.sale_person_reg_no);
      setValue("phone", user.phone);
      setValue("google_id", user.google_id);
      setValue("facebook_id", user.facebook_id);
      setValue("ref_code", user.ref_code);
      setValue("is_access_contact", user.is_access_contact);
      setValue("is_access_location", user.is_access_location);
      setValue("is_access_media", user.is_access_media);
      setValue("is_access_microphone", user.is_access_microphone);
      setValue("username", user.username);
      setValue("first_name", user.first_name);
      setValue("last_name", user.last_name);
      setValue("address", user.address);
      //   setValue("country", user.country);
      setValue("birthday", user.birthday);
    }
  }, [user]);

  if (!user) {
    return <></>;
  }

  return (
    <form onSubmit={handleSubmit(updateUser as SubmitHandler<FieldValues>)}>
      <ListItem divider sx={{ py: 2 }}>
        <Grid item sm={3}>
          <Typography sx={listItemSx}>Password</Typography>
        </Grid>
        <Grid item sm={9}>
          <BasicTextBox
            name="password"
            label="Password"
            control={control}
            rules={{ required: true }}
            required
            errors={errors}
            textBoxSx={{ width: 400 }}
            type="password"
          />
        </Grid>
      </ListItem>
      <ListItem sx={{ py: 2 }}>
        <Button variant="contained" sx={{ mb: 2, mt: 1 }} type="submit">
          Update
        </Button>
      </ListItem>
    </form>
  );
};
