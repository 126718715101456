import { IconButton } from "@mui/material";
import React from "react";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";

export const NotificationIcon = () => {
  return (
    <IconButton sx={{ mr: 2, p: 0 }}>
      <CircleNotificationsIcon style={{ color: "#F01C1C", fontSize: 42 }} />
    </IconButton>
  );
};
