import qs from "query-string";
import { useQuery } from "react-query";
import { NominatimLocation } from "../models/tool";

export const useLatLng = (address: string) => {
  return useQuery(
    ["address", address],
    async () => {
      const params = {
        q: address,
        format: "json",
        polygon: 1,
        addressdetails: 1,
      };
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?${qs.stringify(params)}`
      );
      return (await response.json()) as NominatimLocation[];
    },
    { enabled: !!address }
  );
};
