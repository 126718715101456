import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Listing } from "models/listing";
import { textStyle } from "../../helpers/helper";
import { FavoriteButton } from "../form/Favorite";
import { Link, useNavigate } from "react-router-dom";

export const ListingCard = ({ listing }: { listing: Listing }) => {
  return (
    <Link to={`/listing/${listing.id}/${listing.location.replace(/ /g, "-")}`}>
      <Card sx={{ height: 400, cursor: "pointer" }}>
        <CardContent>
          <Box height={200}>
            <img src={listing.path} height="100%" />
          </Box>
          <Box sx={{ mt: 1 }}>
            <div
              style={{
                width: "10px",
                height: "10px",
                background: "#F01C1C",
                borderRadius: "50%",
                display: "inline-block",
              }}
            />
            <Typography
              sx={{
                display: "inline-block",
                paddingLeft: 2,
                ...textStyle.text,
              }}
              color="text.secondary"
              gutterBottom
            >
              {listing.country}
            </Typography>
            <Box
              sx={{
                ...textStyle.text,
                fontWeight: 600,
                fontSize: 24,
              }}
            >
              $S{listing.price.toLocaleString()}
            </Box>
            <FavoriteButton />
          </Box>
          <Box sx={{ pt: 1 }}>
            {listing.bed && (
              <>
                <b>{listing.bed}</b> beds
              </>
            )}
            {listing.bath && (
              <>
                {listing.bed && " "}
                <b>{listing.bath}</b> baths
              </>
            )}
            {listing.square && (
              <>
                {(listing.bed || listing.bath) && " "}
                <b>{listing.square}</b> sq ft
              </>
            )}
          </Box>
          <Box sx={{ pt: 1, ...textStyle.text }}>{listing.location}</Box>
        </CardContent>
      </Card>
    </Link>
  );
};
