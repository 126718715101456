import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";

export const FavoriteButton = () => {
  const [liked, setLiked] = useState(false);

  return (
    <Box sx={{ float: "right" }}>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          setLiked(!liked);
        }}
      >
        {liked ? <StarIcon style={{ color: "#F01C1C" }} /> : <StarBorderIcon />}
      </IconButton>
    </Box>
  );
};
