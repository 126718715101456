import { Param } from "../models/param";

export const getQueryParamString = (param: Param) => {
  return param.paramValue ? `${param.paramName}=${param.paramValue}` : "";
};

export const getQueryParamsString = (params: Param[]) => {
  const paramsString = params.map((param) => getQueryParamString(param));
  return paramsString.reduce(
    (queryString, paramString) =>
      `${queryString}${paramString}${paramString ? "&" : ""}`,
    "?"
  );
};
