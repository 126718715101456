import { Listing } from "models/listing";
import { useMutation } from "react-query";
import { requestPostImls } from "../helpers/requestHelper";

export const useCreateListing = ({ token, onSuccess, onError }: any) => {
  return useMutation(
    async (listing: Listing) => {
      if (listing.location && !listing.name) {
        listing.name = listing.location;
      }
      return await requestPostImls<Listing, Listing>(
        `listing/create_with_images?token=${token}`,
        listing
      );
    },
    {
      onSuccess,
      onError,
    }
  );
};
