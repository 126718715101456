import React from "react";
import { IconButton } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Link } from "react-router-dom";

export const AddListingIcon = () => {
  return (
    <Link to="/new-listing">
      <IconButton sx={{ p: 0, mr: 1 }}>
        <AddCircleIcon style={{ color: "#F01C1C", fontSize: 42 }} />
      </IconButton>
    </Link>
  );
};
