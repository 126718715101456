import React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useListing } from "../../hooks/useListing";
import "react-image-gallery/styles/css/image-gallery.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";

export const icon = L.icon({ iconUrl: "/images/marker-icon.png" });

export const WebViewMap = () => {
  const { listingId, width, height } = useParams();

  const { data: listing } = useListing(listingId as unknown as number);

  if (!listing) {
    return <></>;
  }

  return (
    <Box>
      {listing.lat && listing.lng && (
        <MapContainer
          center={[listing.lat, listing.lng]}
          zoom={13}
          scrollWheelZoom={false}
          style={{
            height: +height || 800,
            width: +width || 360,
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[listing.lat, listing.lng]} icon={icon}>
            <Popup>{listing.location}</Popup>
          </Marker>
        </MapContainer>
      )}
    </Box>
  );
};
