export const apiHost = window.location.href.includes("referror.com")
  ? "https://imls.referror.com/"
  : "http://34.87.52.105:8080/";
// export const apiHost = "http://localhost:8080/";

export const textStyle = {
  text: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
  },
};

export const isObject = (obj: any) => {
  if (typeof obj === "object" && !Array.isArray(obj) && obj !== null) {
    return true;
  }
  return false;
};

export const isValidJson = (jsonString: string) => {
  try {
    return isObject(JSON.parse(jsonString));
  } catch (error) {
    return false;
  }
};

export const getErrorMessage = (error: any) => {
  const jsonError = isValidJson(error.message)
    ? JSON.parse(error.message as string)
    : {};

  if ("detail" in jsonError && typeof jsonError.detail === "string") {
    return jsonError.detail;
  }

  if (
    "detail" in jsonError &&
    Array.isArray(jsonError.detail) &&
    jsonError.detail.length &&
    "msg" in jsonError.detail[0]
  ) {
    return jsonError.detail[0].msg;
  }

  return "";
};
