import React from "react";
import ReactDOM from "react-dom/client";
import HomePage from "./components/common/HomePage";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeList from "./components/Page/Listing/HomeList";
import NavBar from "./components/common/NavBar";
import { createTheme, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-notifications/lib/notifications.css";
import "./css/index.css";
import { NotificationContainer } from "react-notifications";
import { Listing } from "./components/common/Listing";
import { SearchListing } from "./components/Page/Listing/SearchListing";
import { Profile } from "./components/Page/User/Profile";
import { MyListings } from "components/Page/MyListings/MyListings";
import { NewListing } from "components/Page/MyListings/NewListing";
import { EditListing } from "components/Page/MyListings/EditListing";
import { BecomeAgent } from "components/Page/User/BecomeAgent";
import { Favourites } from "components/Page/User/Favorites";
import { Notification } from "components/Page/User/Notification";
import { Settings } from "components/Page/User/Settings";
import { WebViewMap } from "components/common/WebViewMap";
import { MortgageCalculator } from "components/Page/Mortgage/Calculator";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
  },
  palette: {
    primary: {
      main: "#ED1B24",
    },
    secondary: {
      main: "#1877F2",
    },
    // grey: grey[900],
  },
});

const queryClient = new QueryClient();

root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/map/listing/:listingId/:width/:height"
              element={<WebViewMap />}
            />
            <Route
              path="*"
              element={
                <>
                  <NavBar />
                  <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/homes" element={<HomeList />} />
                    <Route
                      path="/listing/:listingId/:listingLocation"
                      element={<Listing />}
                    />
                    <Route
                      path="/listings/:location"
                      element={<SearchListing />}
                    />
                    <Route
                      path="/listings/listing_type/:sale_rent"
                      element={<SearchListing />}
                    />
                    <Route
                      path="/mortgage/calculator"
                      element={<MortgageCalculator />}
                    />
                    <Route path="/profile" element={<Profile />} />
                    <Route
                      path="/user/become_agent"
                      element={<BecomeAgent />}
                    />
                    <Route path="/user/favourites" element={<Favourites />} />
                    <Route
                      path="/user/notification"
                      element={<Notification />}
                    />
                    <Route path="/user/settings" element={<Settings />} />
                    <Route path="/my-listings" element={<MyListings />} />
                    <Route
                      path="/my-listings/:listingStatus"
                      element={<MyListings />}
                    />
                    <Route path="/new-listing" element={<NewListing />} />
                    <Route
                      path="/my-listings/edit/:listingId"
                      element={<EditListing />}
                    />
                  </Routes>
                </>
              }
            />
          </Routes>
        </BrowserRouter>

        <NotificationContainer />
      </QueryClientProvider>
    </React.StrictMode>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
