import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import { ProfileTitle } from "./ProfileTitle";
import { ImlsList } from "./ImlsList";
import { ProfileMenu } from "./ProfileMenu";
import { useForm } from "react-hook-form";
import { GeneralUpdate } from "./ProfileUpdate/GeneralUpdate";
import { EmailUpdate } from "./ProfileUpdate/EmailUpdate";
import { PasswordUpdate } from "./ProfileUpdate/PasswordUpdate";

export const Profile = () => {
  const token = localStorage.getItem("token");
  const { data: user } = useUser(token);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEmailEditMode, setEmailIsEditMode] = useState(false);
  const [isPasswordEditMode, setPasswordIsEditMode] = useState(false);

  const {
    formState: { errors },
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
  } = useForm();

  if (!user) {
    return <></>;
  }

  return (
    <Grid container spacing={2} my={2} px={4}>
      <ProfileMenu active="User Profile" />
      <Grid item sm={8} xs={12}>
        <ProfileTitle title="User profile" />
        <ImlsList
          title="Profile"
          items={[
            {
              label: "Full name",
              value: `${user.first_name} ${user.last_name}`,
            },
            {
              label: "Date of birth",
              value: user.birthday,
            },
            {
              label: "Phone number",
              value: user.phone,
            },
          ]}
          listSx={{ borderRadius: "10px 10px 0px 0px" }}
          isEditable
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          editContent={<GeneralUpdate setIsEditMode={setIsEditMode} />}
        />
        <ImlsList
          title="Email"
          items={[
            {
              label: "Email address",
              value: user.email,
            },
          ]}
          listSx={{ borderRadius: 0 }}
          titleSx={{ borderRadius: 0 }}
          isEditable
          isEditMode={isEmailEditMode}
          setIsEditMode={setEmailIsEditMode}
          editContent={<EmailUpdate setIsEditMode={setEmailIsEditMode} />}
        />
        <ImlsList
          title="Change password"
          items={[
            {
              label: "Password",
              value: "********",
            },
          ]}
          titleSx={{ borderRadius: 0 }}
          isEditable
          isEditMode={isPasswordEditMode}
          setIsEditMode={setPasswordIsEditMode}
          editContent={<PasswordUpdate setIsEditMode={setPasswordIsEditMode} />}
        />
      </Grid>
    </Grid>
  );
};
