import { Box, Step, StepLabel, Stepper } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

const steps = ["Location", "Property Info", "Photos", "Summary"];

export const NewListingSteps = ({
  step,
  setStep,
}: {
  step: number;
  setStep: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <Box sx={{ width: "100%", my: 2 }}>
      <Stepper activeStep={step} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => setStep(index + 1)}
            sx={{ cursor: "pointer" }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
