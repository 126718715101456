import { Listing } from "models/listing";
import { useMutation } from "react-query";
import { requestPostImls } from "../helpers/requestHelper";

export const useUpdateListing = ({
  listingId,
  token,
  onSuccess,
  onError,
}: any) => {
  return useMutation(
    async (listing: Listing) => {
      if (listing.location && !listing.name) {
        listing.name = listing.location;
      }
      return await requestPostImls<Listing, Listing>(
        `listing/update_with_images/${listingId}?token=${token}`,
        listing
      );
    },
    {
      onSuccess,
      onError,
    }
  );
};
