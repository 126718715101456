import React, { ReactNode } from "react";

const borderStyle = {
  borderBottom: "1px solid #707070",
  width: "100%",
};

const contentStyle = {
  paddingLeft: 12,
  paddingRight: 12,
  fontWeight: 600,
  fontSize: 20,
  fontFamily: "Montserrat",
  color: "#707070",
};

export const DividerWithText = ({ children }: { children: ReactNode }) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={borderStyle} />
      <span style={contentStyle}>{children}</span>
      <div style={borderStyle} />
    </div>
  );
};
