import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { textStyle } from "helpers/helper";
import { useSearchListingLocation } from "hooks/useSearchListingLocation";
import { Listing } from "models/listing";
import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import AvatarIcon from "../User/imgs/avatar.svg";
import { ListingStatus } from "./ListingStatus";
import EditIcon from "@mui/icons-material/Edit";

export const MyListings = () => {
  const { listingStatus } = useParams();
  const [status, setStatus] = useState<string | string[]>(
    listingStatus || "Active"
  );
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data: user } = useUser(token);
  const { data: listings = [] } = useSearchListingLocation(
    token,
    { owner_id: !!user ? user.id : 0, status_list: status },
    {
      enabled: !!user,
    }
  );
  if (!user) {
    return <></>;
  }

  return (
    <Grid container spacing={2} my={2} px={4}>
      <Grid item sm={4} xs={12} sx={{ mb: 3 }}>
        <Box sx={{ alignItems: "center", textAlign: "center", mb: 3 }}>
          <img src={AvatarIcon} />
          <Typography
            sx={{ fontWeight: 700, fontSize: 24 }}
          >{`${user.first_name} ${user.last_name}`}</Typography>
          <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#707070" }}>
            {user.role ?? "Home Buyer"}
          </Typography>
        </Box>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Typography variant="h6">{`${user.first_name} ${user.last_name}`}</Typography>
        <Typography variant="body2">{`${user.phone} | ${user.email}`}</Typography>
      </Grid>
      <Grid item sm={4} xs={12}>
        <Button variant="contained" href="/new-listing" sx={{ mb: 2, mt: 1 }}>
          + Add New Listing
        </Button>
      </Grid>
      <Grid container spacing={2} my={2} px={4}>
        <Grid item sm={2} xs={12} mx={1}>
          <Typography variant="h6">Listings</Typography>
          <ListingStatus status={status} setStatus={setStatus} />
        </Grid>
        <Grid container spacing={2} sm={10} xs={10}>
          {listings?.map((homeListing: Listing) => {
            const listingUrl = `/listing/${
              homeListing.id
            }/${homeListing.location.replace(/ /g, "-")}`;
            return (
              <Grid item sm={3} xs={3}>
                <Card
                  sx={{ height: 400 }}
                  // onClick={() =>
                  //   navigate(
                  //     `/listing/${
                  //       homeListing.id
                  //     }/${homeListing.location.replace(/ /g, "-")}`
                  //   )
                  // }
                >
                  <CardContent>
                    <Box height={200} sx={{ position: "relative" }}>
                      <Link to={listingUrl}>
                        <img alt="" src={homeListing.path} height="100%" />
                      </Link>
                      <IconButton
                        sx={{ position: "absolute", right: 0, top: 0 }}
                        href={`/my-listings/edit/${homeListing.id}`}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ mt: 1 }}>
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          background: "#F01C1C",
                          borderRadius: "50%",
                          display: "inline-block",
                        }}
                      />
                      <Typography
                        sx={{
                          display: "inline-block",
                          paddingLeft: 2,
                          ...textStyle.text,
                        }}
                        color="text.secondary"
                        gutterBottom
                      >
                        {homeListing.country}
                      </Typography>
                      <Box
                        sx={{
                          ...textStyle.text,
                          fontWeight: 600,
                          fontSize: 24,
                        }}
                      >
                        $S{homeListing.price.toLocaleString()}
                      </Box>
                    </Box>

                    <Box sx={{ pt: 1 }}>
                      {homeListing.bed && (
                        <>
                          <b>{homeListing.bed}</b> beds
                        </>
                      )}
                      {homeListing.bath && (
                        <>
                          {homeListing.bed && " "}
                          <b>{homeListing.bath}</b> baths
                        </>
                      )}
                      {homeListing.square > 0 && (
                        <>
                          {(homeListing.bed || homeListing.bath) && " "}
                          <b>{homeListing.square}</b> sq ft
                        </>
                      )}
                    </Box>
                    <Link to={listingUrl}>
                      <Box sx={{ pt: 1, ...textStyle.text }}>
                        {homeListing.location}
                      </Box>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};
