import {
  Autocomplete,
  ButtonBase,
  InputBase,
  styled,
  TextField,
} from "@mui/material";
import useDebounce from "hooks/useDebounce";
import { usePalaces } from "hooks/usePlaces";
import React, { useMemo, useState } from "react";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import rightArrow from "../../img/rightArrow.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: "50px",
  backgroundColor: "#FFFFFF",
  boxShadow:
    "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
  "&:hover": {
    backgroundColor: "#FFFFFF",
    boxShadow:
      "0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
  },
  marginLeft: 0,
  marginTop: "15px",
  marginBottom: "15px",
  height: "50px",
  width: "auto",
  display: "flex",
  [theme.breakpoints.up("sm")]: {
    marginRight: "30px",
    marginLeft: theme.spacing(3),
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& fieldset": {
    border: "none",
    width: "100%",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "50px",
  pointerEvents: "none",
  alignItems: "center",
  justifyContent: "center",
  display: "inline-block",
}));

export default memo<{}>(({}) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [highlightValue, setHighlightValue] = useState("");
  const searchText = useDebounce(value);
  const navigate = useNavigate();

  const { data: placeInstances = [] } = usePalaces(searchText);
  const places = useMemo(
    () =>
      placeInstances.map(
        (placeInstance) => `${placeInstance.name} - ${placeInstance.address}`
      ),
    [placeInstances]
  );

  return (
    <Search>
      <StyledAutocomplete
        options={places}
        freeSolo
        onChange={(_, newValue) => setValue(newValue as string)}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search…"
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            sx={{ height: "50px", width: "100%" }}
            onChange={(event) => {
              setValue(event.target.value);
            }}
          />
        )}
        onKeyDown={(event) => {
          if (event.key === "Enter" && (!highlightValue || !open)) {
            navigate(`listings/${value}`);
          }
        }}
        open={open}
        onHighlightChange={(_, option) => {
          setHighlightValue(option as string);
        }}
        onOpen={() => {
          if (!!value) setOpen(true);
        }}
        onClose={() => setOpen(false)}
        sx={{ width: "100%", minWidth: 350 }}
      />
      <ButtonBase
        style={{ height: "50px", padding: 0 }}
        onClick={() => {
          navigate(`listings/${value}`);
        }}
      >
        <SearchIconWrapper>
          <img
            src={rightArrow}
            alt="right"
            style={{ paddingTop: "3px", paddingRight: "5px" }}
          />
        </SearchIconWrapper>
      </ButtonBase>
    </Search>
  );
});
