import { TextField } from "@mui/material";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

export const BasicTextBox = ({
  name,
  control,
  label,
  rules,
  required,
  errors,
  type,
  multiline,
  rows,
  defaultValue,
  fullWidth,
  textBoxSx,
  InputProps,
}: {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  rules?: any;
  required?: boolean;
  errors?: any;
  type?: string;
  multiline?: boolean;
  rows?: number;
  defaultValue?: string;
  fullWidth?: boolean;
  textBoxSx?: {};
  InputProps?: {};
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <TextField
          onChange={onChange}
          value={value}
          label={label}
          required={required}
          error={errors ? errors[name] : undefined}
          helperText={
            errors && errors[name] && errors[name].type === "required"
              ? `${label} is required`
              : ""
          }
          type={type}
          multiline={multiline}
          rows={rows}
          fullWidth={fullWidth}
          sx={textBoxSx}
          InputProps={InputProps}
        />
      )}
    />
  );
};
