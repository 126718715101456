import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import { ProfileMenu } from "./ProfileMenu";
import { useHomeListing } from "hooks/useHomeListing";
import { ProfileTitle } from "./ProfileTitle";
import { ListingCard } from "components/listing/ListingCard";

export const Favourites = () => {
  const token = localStorage.getItem("token");
  const { data: user } = useUser(token);
  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);
  const [status, setStatus] = useState("init");
  const [isCalledGetLocation, setIsCalledGetLocation] = useState(false);

  const { data: listings } = useHomeListing(lat, lng, "", {
    enabled: !!isCalledGetLocation,
  });

  const getLocation = () => {
    if (!navigator.geolocation) {
      setStatus("not_supported");
    } else {
      setStatus("locating");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus("success");
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          setStatus("not_found");
        }
      );
    }
  };

  useEffect(() => {
    getLocation();
    setIsCalledGetLocation(true);
  });

  if (!user) {
    return <></>;
  }

  return (
    <Grid container spacing={2} my={2} px={4}>
      <ProfileMenu active="Favourites" />
      <Grid item sm={8} xs={12} sx={{ mb: 3 }}>
        <ProfileTitle title="Rent" />
        <Grid container spacing={2} mt={2} mb={6}>
          {listings?.map((listing) => (
            <Grid item sm={3}>
              <ListingCard listing={listing} />
            </Grid>
          ))}
        </Grid>

        <ProfileTitle title="Sale" />
        <Grid container spacing={2} my={2}>
          {listings?.map((listing) => (
            <Grid item sm={3}>
              <ListingCard listing={listing} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
