import qs from "query-string";
import { apiHost } from "./helper";

const defaultHeader = {
  Accept: "application/json",
  "Content-Type": "application/json",
  Cache: "no-cache",
  OneLoginSession: document.cookie,
};

export async function requestGet<ResponseType>(
  host: string,
  path: string,
  params?: object,
  headers?: Record<string, string>
): Promise<ResponseType> {
  const requestHeaders = { ...defaultHeader, ...headers };
  const options = {
    method: "GET" as const,
    credentials: "include" as const,
    // mode: "cors" as const,
    headers: requestHeaders,
  };

  const query = params ? `?${qs.stringify(params)}` : "";

  const response = await fetch(`${host}${path}${query}`, options).then(
    (res) => {
      if (!res.ok) {
        return res.text().then((text) => {
          throw new Error(text);
        });
      }
      return res;
    }
  );

  const jsonResponse = await response.json();
  return jsonResponse;
}

export async function requestPost<RequestBodyType, ResponseType>(
  host: string,
  path: string,
  body: RequestBodyType,
  headers?: Record<string, string>
): Promise<ResponseType> {
  const requestHeaders = { ...defaultHeader, ...headers };
  const options = {
    method: "POST" as const,
    credentials: "include" as const,
    // mode: "cors" as const,
    headers: requestHeaders,
  };

  const finalOptions = {
    ...options,
    body: JSON.stringify(body),
  };

  const response = await fetch(`${host}${path}`, finalOptions).then((res) => {
    if (!res.ok) {
      return res.text().then((text) => {
        throw new Error(text);
      });
    }
    return res;
  });

  const jsonResponse = await response.json();
  return jsonResponse;
}

export async function requestGetImls<ResponseType>(
  path: string,
  params?: object,
  headers?: Record<string, string>
): Promise<ResponseType> {
  return requestGet(apiHost, path, params, headers);
}

export async function requestPostImls<RequestBodyType, ResponseType>(
  path: string,
  body: RequestBodyType,
  headers?: Record<string, string>
): Promise<ResponseType> {
  return requestPost(apiHost, path, body, headers);
}
