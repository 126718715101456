import { AppBar, Container, IconButton, styled, Toolbar } from "@mui/material";
import React from "react";
import { memo } from "react";
import { Link } from "react-router-dom";
import logo from "../../img/logo.svg";
import DehazeIcon from "@mui/icons-material/Dehaze";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import SearchField from "./SearchField";
import { OpenLoginDialogButton } from "./OpenLoginDialogButton";
import { NotificationIcon } from "./NotificationIcon";
import { AddListingIcon } from "./AddListingIcon";

const Logo = styled("img")(({ theme }) => ({
  marginRight: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#000000",
  fontWeight: "700",
  fontSize: "1.2em",
  paddingRight: "20px",
  [theme.breakpoints.down("md")]: {
    fontSize: "1em",
    paddingRight: "10px",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

const StyledMenuIcon = styled(DehazeIcon)(({ theme }) => ({
  color: "#F01C1C",
  margin: "15px",
  marginLeft: "0px",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

const StyledMinimalButton = styled(IconButton)(({ theme }) => ({
  color: "#DCDCDC",
  "&:hover": {
    color: "#F01C1C",
  },
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));

export default memo<{}>(() => {
  return (
    <AppBar
      position="sticky"
      style={{
        backgroundColor: "#FFFFFF",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <StyledLink to={"/"}>
              <Logo src={logo} alt="IMLS" />
            </StyledLink>

            <StyledMenuIcon />

            <SearchField />

            <div>
              <StyledLink to={"/"}>Home</StyledLink>
              <StyledLink to={"/listings/listing_type/buy/"}>Buy</StyledLink>
              <StyledLink to={"/listings/listing_type/rent/"}>Rent</StyledLink>
              <StyledLink to={"/mortgage/calculator"}>
                Mortgage Calculator
              </StyledLink>
            </div>
          </div>
          <div>
            <AddListingIcon />
            <NotificationIcon />
            <OpenLoginDialogButton />
            <StyledMinimalButton size="small">
              <AccountCircleIcon style={{ fontSize: "42px" }} />
            </StyledMinimalButton>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
});
