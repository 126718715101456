import React from "react";
import { Box, Grid, MenuItem, MenuList, Typography } from "@mui/material";
import AvatarIcon from "./imgs/avatar.svg";
import { ReactComponent as AgentIcon } from "./imgs/agent.svg";
import { ReactComponent as FavoriteIcon } from "./imgs/favourite.svg";
import { ReactComponent as NotificationIcon } from "./imgs/notification.svg";
import { ReactComponent as SettingIcon } from "./imgs/setting.svg";
import { useUser } from "hooks/useUser";
import { useNavigate } from "react-router-dom";

export const ProfileMenuItem = ({
  label,
  icon,
  active = "",
  link,
}: {
  label: string;
  icon: any;
  active: string;
  link: string;
}) => {
  const SpecificIcon = icon;
  const isActive = active === label;
  const navigate = useNavigate();
  return (
    <MenuItem
      sx={{
        backgroundColor: isActive ? "#ED1B24" : "",
        color: isActive ? "#fff" : "",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#DCDCDC",
        py: 1.5,
        "&:hover": {
          color: "#000",
        },
      }}
      onClick={() => navigate(link)}
    >
      <SpecificIcon
        fill={isActive ? "#fff" : "#000"}
        style={{ width: 13, marginRight: 10 }}
      />
      {label}
    </MenuItem>
  );
};

export const ProfileMenu = ({ active }: { active: string }) => {
  const token = localStorage.getItem("token");
  const { data: user } = useUser(token);

  if (!user) {
    return <></>;
  }
  return (
    <Grid item sm={4} xs={12} sx={{ mb: 3 }}>
      <Box sx={{ alignItems: "center", textAlign: "center", mb: 3 }}>
        <img src={AvatarIcon} />
        <Typography
          sx={{ fontWeight: 700, fontSize: 24 }}
        >{`${user.first_name} ${user.last_name}`}</Typography>
        <Typography sx={{ fontWeight: 500, fontSize: 16, color: "#707070" }}>
          {user.role ?? "Home Buyer"}
        </Typography>
      </Box>
      <Box>
        <MenuList
          sx={{
            borderWidth: 1,
            borderStyle: "solid",
            borderColor: "#DCDCDC",
            p: 0,
            width: "60%",
            mx: "auto",
          }}
        >
          <ProfileMenuItem
            active={active}
            label="Become Agent"
            icon={AgentIcon}
            link="/user/become_agent"
          />
          <ProfileMenuItem
            active={active}
            label="User Profile"
            icon={AgentIcon}
            link="/profile"
          />
          <ProfileMenuItem
            active={active}
            label="Favourites"
            icon={FavoriteIcon}
            link="/user/favourites"
          />
          <ProfileMenuItem
            active={active}
            label="Notification"
            icon={NotificationIcon}
            link="/user/notification"
          />
          <ProfileMenuItem
            active={active}
            label="Settings"
            icon={SettingIcon}
            link="/user/settings"
          />
        </MenuList>
      </Box>
    </Grid>
  );
};
