import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  ListItem,
  Typography,
} from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import { ProfileTitle } from "./ProfileTitle";
import { ImlsList, listItemSx } from "./ImlsList";
import { ProfileMenu } from "./ProfileMenu";
import { BasicTextBox } from "components/form/BasicTextBox";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useBecomeAgent } from "hooks/useBecomeAgent";
import { Agency } from "models/agency";
import { getErrorMessage } from "helpers/helper";
import { NotificationManager } from "react-notifications";

export const BecomeAgent = () => {
  const token = localStorage.getItem("token");
  const { data: user } = useUser(token);

  const { mutate: createAgency } = useBecomeAgent({
    token,
    onSuccess: (_: Agency) =>
      NotificationManager.success(
        "Agency profile was created!",
        "Agency profile was created"
      ),
    onError: (error: any) =>
      NotificationManager.error(
        getErrorMessage(error),
        "Failed to create new listing"
      ),
  });

  const {
    formState: { errors },
    control,
    handleSubmit,
    trigger,
    setValue,
    getValues,
  } = useForm();

  if (!user) {
    return <></>;
  }

  return (
    <Grid container spacing={2} my={2} px={4}>
      <ProfileMenu active="Become Agent" />
      <Grid item sm={8} xs={12}>
        <ProfileTitle title="Become Agent" />
        <ImlsList
          title="Agency profile"
          content={
            <form
              onSubmit={handleSubmit(
                createAgency as SubmitHandler<FieldValues>
              )}
            >
              <ListItem divider sx={{ py: 2 }}>
                <Grid item sm={3}>
                  <Typography sx={listItemSx}>Agency Name</Typography>
                </Grid>
                <Grid item sm={9}>
                  <BasicTextBox
                    name="name"
                    label="Name"
                    control={control}
                    rules={{ required: true }}
                    required
                    errors={errors}
                    textBoxSx={{ width: 400 }}
                  />
                </Grid>
              </ListItem>
              <ListItem divider sx={{ py: 2 }}>
                <Grid item sm={3}>
                  <Typography sx={listItemSx}>CEA Number</Typography>
                </Grid>
                <Grid item sm={9}>
                  <BasicTextBox
                    name="cea"
                    label="CEA Number"
                    control={control}
                    rules={{ required: true }}
                    required
                    errors={errors}
                    textBoxSx={{ width: 400 }}
                  />
                </Grid>
              </ListItem>
              <ListItem divider sx={{ py: 2 }}>
                <Grid item sm={3}>
                  <Typography sx={listItemSx}>Agency License No.</Typography>
                </Grid>
                <Grid item sm={9}>
                  <BasicTextBox
                    name="agency_license_no"
                    label="Agency License No."
                    control={control}
                    rules={{ required: true }}
                    required
                    errors={errors}
                    textBoxSx={{ width: 400 }}
                  />
                </Grid>
              </ListItem>
              <ListItem divider sx={{ py: 2 }}>
                <Grid item sm={3}>
                  <Typography sx={listItemSx}>Job Destination</Typography>
                </Grid>
                <Grid item sm={9}>
                  <BasicTextBox
                    name="job_destination"
                    label="Job Destination"
                    control={control}
                    rules={{ required: true }}
                    required
                    errors={errors}
                    textBoxSx={{ width: 400 }}
                  />
                </Grid>
              </ListItem>
              <ListItem divider sx={{ py: 2 }}>
                <Grid item sm={3}>
                  <Typography sx={listItemSx}>Specialization</Typography>
                </Grid>
                <Grid item sm={9}>
                  <BasicTextBox
                    name="specialization"
                    label="Specialization"
                    control={control}
                    rules={{ required: true }}
                    required
                    errors={errors}
                    textBoxSx={{ width: 400 }}
                    multiline
                    rows={3}
                  />
                </Grid>
              </ListItem>
              <ListItem divider sx={{ py: 2 }}>
                <Grid item sm={3}>
                  <Typography sx={listItemSx}>Website</Typography>
                </Grid>
                <Grid item sm={9}>
                  <BasicTextBox
                    name="website"
                    label="Website"
                    control={control}
                    rules={{ required: true }}
                    required
                    errors={errors}
                    textBoxSx={{ width: 400 }}
                  />
                </Grid>
              </ListItem>
              <ListItem sx={{ py: 2 }}>
                <FormControlLabel
                  control={<Checkbox />}
                  label="I hereby that I have read and agreed to the Privacy Policy and Terms and Conditions which governs the use of imls"
                  sx={{ width: "100%", mb: 2 }}
                />
              </ListItem>
              <ListItem sx={{ py: 2 }}>
                <Button type="submit" variant="contained" sx={{ mb: 2, mt: 1 }}>
                  Submit
                </Button>
              </ListItem>
            </form>
          }
        />
      </Grid>
    </Grid>
  );
};
