import { useQuery } from "react-query";
import { getQueryParamsString } from "../helpers/queryString";
import { requestGetImls } from "../helpers/requestHelper";
import { Listing } from "../models/listing";

export const useHomeListing = (
  lat: number,
  lng: number,
  country: string,
  options?: {}
) => {
  return useQuery(
    "home_listings",
    async () => {
      const response = await requestGetImls<Listing[]>(
        `listing/home/${getQueryParamsString([
          { paramName: "lat", paramValue: lat },
          { paramName: "lng", paramValue: lng },
          { paramName: "country", paramValue: country },
        ])}`
      );
      return response;
    },
    options
  );
};
