import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { Control, Controller, FieldValues } from "react-hook-form";

export const BasicAutocomplete = ({
  name,
  control,
  label,
  rules,
  required,
  errors,
  options,
  defaultValue,
}: {
  name: string;
  control: Control<FieldValues, any>;
  label: string;
  rules?: any;
  required?: boolean;
  errors?: any;
  options: string[];
  defaultValue?: string;
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          options={options}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              required={required}
              error={errors ? errors[name] : undefined}
              helperText={
                errors && errors[name] && errors[name].type === "required"
                  ? `${label} is required`
                  : ""
              }
            />
          )}
          onChange={(_, option) => {
            onChange(option);
          }}
          value={value}
        />
      )}
    />
  );
};
