import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAhOGE9iCYp7_pOlbgqBzwl1YkvlEnhMqM",
  authDomain: "webrtc-quang.firebaseapp.com",
  projectId: "webrtc-quang",
  storageBucket: "webrtc-quang.appspot.com",
  messagingSenderId: "946927234001",
  appId: "1:946927234001:web:eeebbc45e0c95c06a38ef0",
  measurementId: "G-WT6DKT10BB",
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
