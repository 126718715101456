import React from "react";
import { useParams } from "react-router-dom";
import { useListing } from "hooks/useListing";
import { ListingForm } from "components/form/ListingForm";

export const EditListing = () => {
  const { listingId } = useParams();
  const { data: listing } = useListing(listingId as unknown as number);

  return <ListingForm listing={listing} isEdit />;
};
