import { useQuery } from "react-query";
import { requestGetImls } from "../helpers/requestHelper";
import { Listing } from "../models/listing";
const queryString = require("query-string");

export const useSearchListingLocation = (
  token: string,
  params: {},
  option?: {}
) => {
  const stringified = queryString.stringify(params);
  return useQuery(
    ["search_listing", params],
    async () => {
      const response = await requestGetImls<Listing[]>(
        `listing/search/?token=${token}&${stringified}`
      );
      return response;
    },
    option
  );
};
