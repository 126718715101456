import { Autocomplete, Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { KeyValue } from "../../../models/common";

const propertyTypes = [
  { value: "Rented", label: "Rented" },
  { value: "Condo", label: "Condo" },
  { value: "Buildings", label: "Buildings" },
  { value: "HDB", label: "HDB" },
  { value: "Land", label: "Land" },
  { value: "Office", label: "Office" },
  { value: "Shop House", label: "Shop House" },
  { value: "Retail", label: "Retail" },
  { value: "Other", label: "Other" },
];

const badTypes = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5+" },
];

const autocompleteStyles = { display: "inline-block", mr: 2, mt: 1 };

export const SearchFilter = ({
  searchParams,
  setSearchParams,
}: {
  searchParams: KeyValue;
  setSearchParams: any;
}) => {
  const [propertyType, setPropertyType] = useState("");
  const [bad, setBad] = useState("");
  const [bed, setBed] = useState("");
  const [filter, setFilter] = useState(searchParams);

  return (
    <Box>
      <Autocomplete
        options={propertyTypes}
        sx={{ ...autocompleteStyles, width: 200 }}
        renderInput={(params) => (
          <TextField {...params} label="Property Type" />
        )}
        onChange={(_, option) => {
          if (
            (option as { value: string; label: string }).value !== undefined
          ) {
            setFilter({
              ...filter,
              property_type: (option as { value: string; label: string }).value,
            });
            setPropertyType((option as { value: string; label: string }).value);
          } else {
            const { property_type: _, ...newParams } = searchParams;
            setSearchParams(newParams);
          }
        }}
        value={propertyType}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "clear") {
            setPropertyType("");
            const { property_type: _, ...newFilter } = filter;
            setFilter(newFilter);
            return;
          }
        }}
      />
      <Autocomplete
        options={badTypes}
        sx={{ ...autocompleteStyles, width: 100 }}
        renderInput={(params) => <TextField {...params} label="Bad" />}
        onChange={(_, option) => {
          if (
            (option as { value: string; label: string }).value !== undefined
          ) {
            setFilter({
              ...filter,
              bad: (option as { value: string; label: string }).value,
            });
            setBad((option as { value: string; label: string }).value);
          } else {
            const { bad: _, ...newFilter } = filter;
            setSearchParams(newFilter);
          }
        }}
        value={bad}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "clear") {
            setBad("");
            const { bad: _, ...newFilter } = filter;
            setFilter(newFilter);
            return;
          }
        }}
      />
      <Autocomplete
        options={badTypes}
        sx={{ ...autocompleteStyles, width: 100 }}
        renderInput={(params) => <TextField {...params} label="Bed" />}
        onChange={(_, option) => {
          if (
            (option as { value: string; label: string }).value !== undefined
          ) {
            setFilter({
              ...filter,
              bed: (option as { value: string; label: string }).value,
            });
            setBed((option as { value: string; label: string }).value);
          } else {
            const { bed: _, ...newFilter } = filter;
            setFilter(newFilter);
          }
        }}
        value={bed}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "clear") {
            setBed("");
            const { bed: _, ...newFilter } = filter;
            setFilter(newFilter);
            return;
          }
        }}
      />
      <Button
        variant="contained"
        sx={{ height: 56, verticalAlign: "bottom" }}
        onClick={() => setSearchParams(filter)}
      >
        Apply
      </Button>
    </Box>
  );
};
