import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

const calculateMonthly = (
  principal: number,
  interest: number,
  payments: number
) => {
  const newInterest = interest / 100 / 12;
  return (
    newInterest * (1 / (1 - (1 + newInterest) ** (-payments * 12))) * principal
  );
};

export const MortgageCalculator = () => {
  const [loanAmount, setLoanAmount] = useState(0);
  const [loanTerm, setLoanTerm] = useState(1);
  const [interestRate, setInterestRate] = useState(1);
  const [monthPayment, setMonthPayment] = useState(0);

  useEffect(() => {
    setMonthPayment(calculateMonthly(loanAmount, interestRate, loanTerm));
  }, [loanAmount, interestRate, loanTerm]);

  return (
    <Grid container spacing={6} my={2} px={4}>
      <Grid item xs={12} sm={8}>
        <FormControl sx={{ mb: 4 }} fullWidth>
          <TextField
            name="loan_amount"
            label="Loan Amount"
            required
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            value={loanAmount}
            onChange={(event) =>
              setLoanAmount(event.target.value as unknown as number)
            }
          />
        </FormControl>
        <FormControl sx={{ mb: 4 }} fullWidth>
          <Typography>Loan Term</Typography>
          <Typography variant="body2">{loanTerm} Years</Typography>
          <Slider
            defaultValue={10}
            aria-label="Default"
            valueLabelDisplay="auto"
            min={1}
            max={35}
            value={loanTerm}
            onChange={(_, newValue) => setLoanTerm(newValue as number)}
          />
        </FormControl>
        <FormControl sx={{ mb: 4 }} fullWidth>
          <Typography>Interest Rate (Per Year)</Typography>
          <Slider
            defaultValue={5}
            aria-label="Default"
            valueLabelDisplay="auto"
            min={1}
            max={20}
            step={0.1}
            value={interestRate}
            onChange={(_, newValue) => setInterestRate(newValue as number)}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box>
          <Typography variant="h5" sx={{ pb: 2 }}>
            Total Payable
          </Typography>
          <Typography variant="h5" sx={{ color: "#F01C1C" }}>
            {(monthPayment * loanTerm * 12).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Typography>
        </Box>
        <Box sx={{ mt: 6 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", pb: 2 }}>
            Monthly Repayment
          </Typography>
          <Typography
            variant="h5"
            sx={{ color: "#F01C1C", fontWeight: "bold" }}
          >
            {monthPayment.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
