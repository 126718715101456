import React from "react";
import { Grid } from "@mui/material";
import { useUser } from "../../../hooks/useUser";
import { ProfileMenu } from "./ProfileMenu";
import { ProfileTitle } from "./ProfileTitle";

export const Notification = () => {
  const token = localStorage.getItem("token");
  const { data: user } = useUser(token);

  if (!user) {
    return <></>;
  }

  return (
    <Grid container spacing={2} my={2} px={4}>
      <ProfileMenu active="Notification" />
      <Grid item sm={8} xs={12} sx={{ mb: 3 }}>
        <ProfileTitle title="Notifications" />
      </Grid>
    </Grid>
  );
};
