import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Link, useParams } from "react-router-dom";
import { textStyle } from "../../../helpers/helper";
import { useSearchListingLocation } from "../../../hooks/useSearchListingLocation";
import { Listing } from "../../../models/listing";
import { icon } from "../../common/Listing";
import { FavoriteButton } from "../../form/Favorite";
import { SearchFilter } from "../../form/filters/SearchFilter";

export const SearchListing = () => {
  const { location, sale_rent } = useParams();
  const token = localStorage.getItem("token");
  const [searchParams, setSearchParams] = useState({
    location,
    sale_rent,
  } as {});

  const { data: listings = [] } = useSearchListingLocation(token, searchParams);

  useEffect(() => {
    setSearchParams({ ...searchParams, sale_rent });
  }, [sale_rent]);

  return (
    <Grid container spacing={2} my={2} px={4}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <SearchFilter
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      </Grid>
      <Grid container sm={6} spacing={2}>
        {listings?.map((homeListing: Listing) => (
          <Grid item sm={6}>
            <Link
              to={`/listing/${homeListing.id}/${homeListing.location.replace(
                / /g,
                "-"
              )}`}
            >
              <Card sx={{ height: 400, cursor: "pointer" }}>
                <CardContent>
                  <Box height={200}>
                    <img alt="" src={homeListing.path} height="100%" />
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <div
                      style={{
                        width: "10px",
                        height: "10px",
                        background: "#F01C1C",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                    <Typography
                      sx={{
                        display: "inline-block",
                        paddingLeft: 2,
                        ...textStyle.text,
                      }}
                      color="text.secondary"
                      gutterBottom
                    >
                      {homeListing.country}
                    </Typography>
                    <Box
                      sx={{
                        ...textStyle.text,
                        fontWeight: 600,
                        fontSize: 24,
                      }}
                    >
                      $S{homeListing.price.toLocaleString()}
                    </Box>
                    <FavoriteButton />
                  </Box>
                  <Box sx={{ pt: 1 }}>
                    {homeListing.bed && (
                      <>
                        <b>{homeListing.bed}</b> beds
                      </>
                    )}
                    {homeListing.bath && (
                      <>
                        {homeListing.bed && " "}
                        <b>{homeListing.bath}</b> baths
                      </>
                    )}
                    {homeListing.square && (
                      <>
                        {(homeListing.bed || homeListing.bath) && " "}
                        <b>{homeListing.square}</b> sq ft
                      </>
                    )}
                  </Box>
                  <Box sx={{ pt: 1, ...textStyle.text }}>
                    {homeListing.location}
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Grid item sm={6} mt={-2}>
        {listings.length > 0 && listings[0].lat && listings[0].lng && (
          <MapContainer
            center={[listings[0].lat, listings[0].lng]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: 816 }}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {listings.map((listing) => {
              return (
                <Marker position={[listing.lat, listing.lng]} icon={icon}>
                  <Popup>{listing.location}</Popup>
                </Marker>
              );
            })}
          </MapContainer>
        )}
      </Grid>
    </Grid>
  );
};
