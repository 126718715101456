import { User } from "models/user";
import { useMutation } from "react-query";
import { requestPostImls } from "../helpers/requestHelper";

export const useUpdateUser = ({ token, onSuccess, onError }: any) => {
  return useMutation(
    async (user: User) => {
      return await requestPostImls<User, User>(
        `user/update?token=${token}`,
        user
      );
    },
    {
      onSuccess,
      onError,
    }
  );
};
