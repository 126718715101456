import { useQuery } from "react-query";
import { requestGetImls } from "../helpers/requestHelper";
import { Listing } from "../models/listing";

export const useListing = (id: number) => {
  return useQuery(["listing", id], async () => {
    const response = await requestGetImls<Listing>(`listing/${id}`);
    return response;
  });
};
